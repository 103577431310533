import * as React from 'react';
import { Link } from 'react-router-dom';
import '../../scss/style.scss';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './style.scss';
import {Pages} from '../../interfaces';



interface IProps{
    active: Pages;
}

export default function NavigationBar(props: IProps){    
  
  const logo = require('../../images/logo-full.png')
    return (
      <Navbar bg="light" expand="lg" fixed="top">
        <div className="container">
          <Navbar.Brand className="cb-brand">                
              <Link to="/" className="navbar-brand">
                <img src={logo} className="logo-img" alt="Central Bodyshop Paintgun Logo" />
              </Link>                          
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarResponsive" />
          <Navbar.Collapse id="navbarResponsive">
            <Nav className="ml-auto">
              <Nav.Item className={`nav-item ${props.active===Pages.Landing && "active"}`} >
                <Link className="nav-link" to="/" >Home</Link>
              </Nav.Item>
              <Nav.Item className={`nav-item ${props.active===Pages.Intro && "active"}`} >
                <Link className="nav-link" to="/intro" >Front</Link>
              </Nav.Item>
              <Nav.Item className={`nav-item ${props.active===Pages.About && "active"}`} >
                <Link className="nav-link" to={{
                  pathname:'/about',
                  state:{
                    pageId:'about',
                    page: Pages.About
                  }
                }}>About</Link>
              </Nav.Item>              
              <Nav.Item className={`nav-item ${props.active===Pages.Services && "active"}`} >
              <Link className="nav-link" to={{
                  pathname:'/services',
                  state:{
                    pageId:'services',
                    page: Pages.Services
                  }
                }}>Services</Link>
              </Nav.Item>
              <Nav.Item className={`nav-item ${props.active===Pages.Admin && "active"}`} >
                <Link className="nav-link" to="/admin" >Admin</Link>
              </Nav.Item>
              <Nav.Item className={`nav-item ${props.active===Pages.Contact && "active"}`} >
                <a className="nav-link" href="https://www.markdownguide.org/basic-syntax/" target="_blank" rel="noopener noreferrer">Markdown</a>
              </Nav.Item>

            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>     
    );
};
