import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { IUser } from '../../interfaces';
import { authenticateUser, resetUsers} from '../../Api/Login';
import { useHistory } from "react-router-dom";
import { IAppState } from '../../state';
import LoadingOverlay from 'react-loading-overlay';

export interface ILogin{
    username: string;
    password: string;
}


export default function LoginForm(){

    const
        [data, setData] = React.useState<ILogin>({ username:"", password: "" }),
        [warning, setWarning] = React.useState(""),
        dispatch = useDispatch(),
        //selector = useSelector((state: UserState) => state),
        user:{currentUser?: IUser | null, error?:any, loading: boolean} = useSelector(({userReducer}: IAppState) => userReducer),
        history = useHistory();

        React.useEffect(() =>{
            localStorage.removeItem("token");
            dispatch(resetUsers(null));
        },[dispatch])

        React.useEffect(() => {               
            let token = localStorage.getItem("token");
            if(token)
                history.push("/");
            else
                setWarning(user.error);
        },[user, history])

        const handleSubmission = () =>{
            dispatch(authenticateUser(data));
        }
    console.log(user.loading);
    return(              
        <LoadingOverlay
            active={user.loading}
            spinner
            text='Please wait, authenticating user...' 
        >      
        <div className="container">            
        <div className="text-warning lead">{warning}</div>

                <form id="contact-form">
                    <div className="form-group">
                        <div className="input-group input-group-lg">
                        <input type="text" className="form-control" onChange={e => setData({username:e.target.value, password: data.password})} required
                        placeholder="User Name" id="nameInput" name="userName" tabIndex={1}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group input-group-lg">
                        <input type="password" className="form-control"  onChange={e => setData({username:data.username, password: e.target.value})} required
                        placeholder="Password" id="emailInput" tabIndex={2}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group input-group-lg">
                        <button className="btn btn-block btn-lg btn-primary" onClick={e => {e.preventDefault(); handleSubmission()}}>Login</button>
                        </div>
                    </div>
                </form>            
        </div>    
        </LoadingOverlay>
    );
};