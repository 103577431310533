import * as React from 'react';
import MainLayout from '../../components/Layout/Main';
import AllUsers from '../../components/AllUsers';
import { Pages } from '../../interfaces';

export default function Login() {

    return (
        <div>
            <MainLayout page={Pages.Users}>
                <AllUsers />
            </MainLayout>
        </div>
    );
};
