import * as React from 'react';
import MainLayout from '../../components/Layout/Main';
import { Pages } from '../../interfaces';
import UpdateUserForm from '../../components/UpdateUserForm';
import { useLocation } from 'react-router-dom';
import { IUser } from '../../interfaces';

export default function UpdateUser() {
    const
        location = useLocation(),
        user = location.state as IUser | null;

    return (
        <div>
            <MainLayout page={Pages.UpdateUser}>
                <UpdateUserForm user={user}/>
            </MainLayout>
        </div>
    );
};
