import * as React from 'react';
import MainLayout from '../../components/Layout/Main';
import {Pages} from '../../interfaces';
import LoginForm from '../../components/LoginForm';
import { useSelector } from 'react-redux';
import {IAppState} from '../../state'
import {IUser} from '../../interfaces';
import PageContents from '../../components/PageContent';




export default function Landing() {
    const user:{currentUser?: IUser | null, error?: any} = useSelector(({userReducer}: IAppState) => userReducer);
    
    return user.currentUser ? (
        <div>
            <MainLayout page={Pages.Landing}>                
                <PageContents pageId={"landing"} preview={true} />
            </MainLayout>
        </div>
    ) :
        <div>
            <MainLayout page={Pages.Login}>
                <LoginForm />
            </MainLayout>
        </div>;
};
