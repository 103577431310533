import * as React from 'react';
import MainLayout from '../../components/Layout/Main';
import { Pages } from '../../interfaces';
import ContentForm from '../../components/PageContent';
import { useLocation } from 'react-router-dom'


interface IPage {
    pageId: string;
    page: Pages
}

export default function Content() {
    const
        location = useLocation(),
        page = location.state as IPage;


    return (
        <div>
            <MainLayout page={page ? page.page : Pages.Landing}>                
                <ContentForm pageId={page.pageId} preview={true} />
            </MainLayout>
        </div>
    );
};
