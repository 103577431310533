import * as React from 'react';
import MainLayout from '../../components/Layout/Main';
import {Pages} from '../../interfaces';
import {Link} from 'react-router-dom';

export default function Login(){
    return(
        <div>
            <MainLayout page={Pages.Admin}>             
            <div className="container">
                <h1>User Administration</h1>
                <div className="lead"><p>Register, delete and update users</p></div>
                <ul>
                    <li><Link to="/register">Register</Link> a new user on the system</li>
                    <li><Link to="/update">Update</Link> your user details and change your password</li>
                    <li><Link to="/users">View</Link> all users and edit their details or change a password</li>
                </ul>                
            </div>
            </MainLayout>
        </div>
    );
};
