import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../state';
import { IUser } from '../../interfaces';
import { getUsers } from '../../Api/Users';
import {Link} from 'react-router-dom'

export default function AllUsers() {
    const
        users: { users?: IUser[] | null, currentUser?: IUser | null, error?: any } = useSelector(({ userReducer }: IAppState) => userReducer),
        dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getUsers());
    }, [dispatch])


    const getContent = () => {
        let html: any = [];
        users.users && users.users.forEach(user => {
            html.push(
                <tr key={user.id}>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.username}</td>
                    <td><Link className="nav-link" to={
                        {
                            pathname: "/update",
                            state: user
                        }
                    } >Edit</Link></td>
                </tr>
            )
        })
        return html;
    };

    return(
        <div className="container">
            <table className="table">                
                <thead><tr>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Username</th>
                <th scope="col">Update</th>
                </tr></thead>     
                <tbody>
                    {getContent()}
                </tbody>
            </table>
        </div>
    )
};