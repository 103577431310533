import * as React from 'react';
import './style.scss';

interface IProps{
    value?: string,
    rows?: number,
    minRows?: number,
    maxRows?: number,
    lineHeight?: number,
    placeHolder?: string,
    className?: string,
    tabIndex?: number,
    onUpdate?: (e: React.FormEvent<HTMLTextAreaElement>) => void
};



function ResizableTextarea(props: IProps, ref: any){
    const
        [value, setValue] = React.useState(""),
        [rows, setRows] = React.useState(5),
        [minRows, setMinRows] = React.useState(5),
        [maxRows, setMaxRows] = React.useState(10),
        [lineHeight, setLineHeight] = React.useState(24),
        [placeHolder, setPlaceHolder] = React.useState("Enter your text here..."),
        [tabIndex, setTabIndex] = React.useState(0),
        [className, setClassName] = React.useState("std-textarea");

        React.useEffect(() => {
            setValue(props.value ? props.value: "");
            props.rows && setRows(props.rows);
            props.minRows && setMinRows(props.minRows);
            props.maxRows && setMaxRows(props.maxRows);
            props.lineHeight && setLineHeight(props.lineHeight);
            props.placeHolder && setPlaceHolder(props.placeHolder);
            props.className && setClassName(props.className);
            props.tabIndex && setTabIndex( props.tabIndex );            
        }, [props]) 

    const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        props.onUpdate && props.onUpdate(e);
        let previousRows =  e.currentTarget.rows;
        e.currentTarget.rows = minRows;
        //~~: faster substitute for Math.floor
        let currentRows = ~~(e.currentTarget.scrollHeight / lineHeight);
        if (currentRows===previousRows) {
            e.currentTarget.rows = currentRows;
        }
        if(currentRows >= maxRows){
            e.currentTarget.rows = maxRows;
            e.currentTarget.scrollTop = e.currentTarget.scrollHeight;
        }
        setValue(e.currentTarget.value);
        setRows(currentRows < maxRows ? currentRows : maxRows);
    };     
    

    return(
        <textarea
            rows={rows}
            value={value}
            placeholder={placeHolder}
            className={className}
            onChange={e => handleChange(e)}      
            tabIndex={tabIndex}
            ref={ref}
        />
    )

};

export default React.forwardRef(ResizableTextarea);