import * as React from 'react';
import { Link } from 'react-router-dom';
import '../../scss/style.scss';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './style.scss';
import {Pages} from '../../interfaces';



interface IProps{
    active: Pages;
}

export default function AdminNavigationBar(props: IProps){    
  
  const logo = require('../../images/logo-full.png')
    return (
      <Navbar bg="light" expand="lg" fixed="top">
        <div className="container">
          <Navbar.Brand className="cb-brand">                
              <Link to="/" className="navbar-brand">
                <img src={logo} className="logo-img" alt="Central Bodyshop Paintgun Logo" />
              </Link>                          
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarResponsive" />
          <Navbar.Collapse id="navbarResponsive">
            <Nav className="ml-auto">
              <Nav.Item className={`nav-item ${props.active===Pages.Intro && "active"}`} >
                <Link className="nav-link" to="/" >Home</Link>
              </Nav.Item>
              <Nav.Item className={`nav-item ${props.active===Pages.Register && "active"}`} >
                <Link className="nav-link" to="/register" >Register</Link>
              </Nav.Item>        
              <Nav.Item className={`nav-item ${props.active===Pages.UpdateUser && "active"}`} >
                <Link className="nav-link" to="/update" >Update User</Link>
              </Nav.Item>
              <Nav.Item className={`nav-item ${props.active===Pages.Users && "active"}`} >
                <Link className="nav-link" to="/users" >View all users</Link>
              </Nav.Item>   
              <Nav.Item className={`nav-item ${props.active===Pages.Login && "active"}`} >
                <Link className="nav-link" to="/login" >Login</Link>
              </Nav.Item>                           
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>     
    );
};
