import * as React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Home from './features/Home';
import Landing from './features/Landing';
import Content from './features/Content';
import Login from './features/Login';
import Register from './features/Register';
import Admin from './features/Admin';
import UpdateUser from './features/UpdateUser';
import Users from './features/Users';
import { useSelector } from 'react-redux';
import {IAppState} from './state'
import {IUser} from './interfaces';



const Router = () => {
    const user:{currentUser?: IUser | null, error?: any} = useSelector(({userReducer}: IAppState) => userReducer);
    return user.currentUser ? (
        <BrowserRouter>
        <React.Fragment>
            <ScrollToTop />            
            <Switch>            
                <Route exact={true} path="/" component={Landing}/>
                <Route path="/intro" component={Home} />
                <Route path="/about" component={Content} />
                <Route path="/services" component={Content} />
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                <Route path="/admin" component={Admin} />
                <Route path="/update" component={UpdateUser} />
                <Route path="/users" component={Users} />
                <Route path="/landing-edit" component={Content} />
                {/* <Route path="/admin/landing" component={Landing} />               
                
                 */}
                {/* Not Found */}
                <Route component={() => <Redirect to="/"/>} />
            </Switch>
            </React.Fragment>
        </BrowserRouter>    
    ):
    <BrowserRouter>
    <React.Fragment>
        <ScrollToTop />            
        <Switch>            
            <Route exact={true} path="/" component={Login}/>
            {/* Not Found */}
            <Route component={() => <Redirect to="/"/>} />
        </Switch>
        </React.Fragment>
    </BrowserRouter>    
}

export default Router;