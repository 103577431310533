import * as React from 'react';
import ResizableTextarea from '../ResizableTextarea';
import './style.scss';
import {  getContentsForPage, updateContent, insertContent } from '../../Api/Content'
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../state';
import TabLayout from '../Layout/EditPreview';
import ReactMarkdown from 'react-markdown';
import { ITabContent } from '../../interfaces';



export interface IContent {
    id: string;
    pageId: string;
    content: string;
};

interface IProps {
    pageId: string;
    preview: boolean;
}


export default function ContentForm(props: IProps) {
    const
        [data, setData] = React.useState<IContent[]>([{ id: "", pageId: "", content: "" }]),
        dispatch = useDispatch(),
        //selector = useSelector((state: IFormState) => state);
        contentData: { contents: IContent[], preview: IContent[], error?: string, loading: boolean } = useSelector(({ contentReducer }: IAppState) => contentReducer);


    React.useEffect(() => {
        dispatch(getContentsForPage(props.pageId));
    }, [dispatch, props])

    React.useEffect(() => {
        contentData.contents.length > 0 ? setData(contentData.contents) : setData([{ id: "", pageId: props.pageId, content: "" }]);
    }, [contentData.contents, props.pageId])



    const handleSubmission = async () => {

        data.forEach(d => {
            dispatch(updateContent(d))
        });
    };

    const handleInsert = async () => {
        data.forEach(d => {
            dispatch(insertContent(d))
        });
    }

    const updateData = (content: IContent) => {
        let newData: IContent[] = [];
        data.forEach(d => {
            d.id === content.id ? newData.push(content) : newData.push(d)
        })

        setData(newData);
    };

    const getPreview = () => {
        let content: any = [];
        data.forEach(d => {
            content.push(
                <ReactMarkdown source={d.content} />
            )
        });
        return content;
    };



    const getTabContent = () => {
            let tabs: ITabContent[] = [];
            tabs.push({
                content: props.preview ? getPreview() : getFormElements(),
                tabName: props.preview ? "Preview" : "Edit"
            });
            tabs.push({
                content: props.preview ? getFormElements() : getPreview() ,
                tabName: props.preview ? "Edit" : "Preview"
            })            
            return tabs;
        }

    const getFormElements = () => {
        let formElements: any = [];
        data.forEach(d => {
            formElements.push(
                <section className="py-2" key={d.id}>
                    <div className="container">
                        <form>
                            <div className="form-group">
                                <div className="input-group input-group-lg ">
                                    <ResizableTextarea className="std-textarea content-area" placeHolder="Message"
                                        value={d.content}
                                        onUpdate={e => updateData({ id: d.id, pageId: d.pageId, content: e.currentTarget.value })} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group input-group-lg">
                                    <button className="btn btn-block btn-lg btn-primary" onClick={e => { e.preventDefault(); d.id === "" ? handleInsert() : handleSubmission() }}>Save Changes</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            )
        });

        return formElements;
    };


    return (
        <div className="container">
            <TabLayout content={getTabContent()} />
        </div>
    )
};