import * as React from 'react';
import Footer from '../../../components/Footer';
import { Pages } from '../../../interfaces';
import LoginNavigationBar from '../../../components/NavigationBar/login-nav';
import AdminNavigationBar from '../../../components/NavigationBar/admin-nav';
import NavigationBar from '../../../components/NavigationBar';

type MainLayoutProps = {
    page: Pages
};

const MainLayout: React.FunctionComponent<MainLayoutProps> = ({ page, children }) => {

    switch (page) {
        case Pages.Login:
            return (<div>
                <LoginNavigationBar />
                {children}
                <Footer />
            </div>)
        case Pages.Register:
        case Pages.UpdateUser:
        case Pages.Admin:
        case Pages.Users:
            return (<div>
                <AdminNavigationBar active={page} />
                {children}
                <Footer />
            </div>)
        default:
            return (<div>
                <NavigationBar active={page} />
                {children}
                <Footer />
            </div>)
    }
};

export default MainLayout


