import * as React from 'react';
import MainLayout from '../../components/Layout/Main';
import {Pages} from '../../interfaces';
import LoginForm from '../../components/LoginForm';


export default function Login(){

    return(
        <div>
            <MainLayout page={Pages.Login}>             
            <LoginForm />
            </MainLayout>
        </div>
    );
};
