import * as React from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import {ITabContent} from '../../../interfaces';

interface IProps{
    content: ITabContent[]
}

export default function TabLayout(props: IProps) {

    const
        [key, setKey] = React.useState("Preview");
    const getTabs = () =>{
        let tabs: any = [];
        let index = 0;
        props.content.forEach(t => {

            tabs.push(
            <Tab eventKey={t.tabName} title={t.tabName} key={index} >{t.content}</Tab>
            )
            index++;
        })
        return tabs;
    }
    return (
        <Tabs
            activeKey={key}
            id="tabs"
            onSelect={(key: React.SetStateAction<string>) => setKey(key)}
        >
            {getTabs()}
        </Tabs>
    )
}