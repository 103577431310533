import * as React from 'react';
import MainLayout from '../../components/Layout/Main';
import {Pages} from '../../interfaces';
import RegistrationForm from '../../components/RegistrationForm';


export default function Register(){

    return(
        <div>
            <MainLayout page={Pages.Register}>             
            <RegistrationForm />
            </MainLayout>
        </div>
    );
};
