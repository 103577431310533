import { API_URL } from '../../common';
import axios from 'axios';
import { IReducerAction } from '../../state/IReducerAction';
import { GET_USERS } from '../../state/action-types';
import { IUser } from '../../interfaces';

export function getUsers() {
    const
        token = localStorage.getItem("token"),
        config = {
            headers: { Authorization: `Bearer ${token}` }
        }
    //API url is the proxy: setting in package.json
    return (dispatch: (arg0: IReducerAction) => any) => {
        axios.get(`${API_URL}/User`,config)
            .then(res => {
                dispatch({
                    type: GET_USERS,
                    payload: res.data as IUser[]
                })
            })

    };
};

