import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { IRegistration } from '../../interfaces';
import registerUser  from '../../Api/Registration'
import { useHistory } from "react-router-dom";
import { IAppState } from '../../state';


export default function RegistrationForm(){

    const
        [data, setData] = React.useState<IRegistration>({firstName:"", lastName:"", username:"", password: "" }),
        [warning, setWarning] = React.useState(""),
        dispatch = useDispatch(),
        register:{data?: string | null, error?: any} = useSelector(({registrationReducer}: IAppState) => registrationReducer),
        history = useHistory();

        React.useEffect(() => {                
            register.data==="success"? history.push("/login"): setWarning(register.error);
        },[register, history])

        const handleSubmission = () =>{
            dispatch(registerUser(data));
        }

    return(        
        <div className="container">            
        <div className="text-warning lead">{warning}</div>
            <form id="contact-form">
            <div className="form-group">
                    <div className="input-group input-group-lg">
                    <input type="text" className="form-control" onChange={e => setData({firstName:e.target.value, lastName:data.lastName, username:data.username, password: data.password})} required
                    placeholder="First Name" id="firsNameInput" name="firstName" tabIndex={1}/>
                    </div>                    
                </div>
                <div className="form-group">
                    <div className="input-group input-group-lg">
                    <input type="text" className="form-control"  onChange={e => setData({firstName:data.firstName, lastName:e.target.value, username:data.username, password: data.password})} required
                    placeholder="Last Name" id="lastNameInput" name="lastName" tabIndex={2}/>
                    </div>                    
                </div>
                <div className="form-group">
                    <div className="input-group input-group-lg">
                    <input type="text" className="form-control" onChange={e => setData({firstName:data.firstName, lastName:data.lastName,username:e.target.value, password: data.password})} required
                    placeholder="User Name" id="userNameInput" name="username" tabIndex={3}/>
                    </div>                    
                </div>
                <div className="form-group">
                    <div className="input-group input-group-lg">
                    <input type="password" className="form-control"  onChange={e => setData({firstName:data.firstName, lastName:data.lastName,username:data.username, password: e.target.value})} required
                    placeholder="Password" id="passwordInput" name="password" tabIndex={4}/>
                    </div>                    
                </div>
                <div className="form-group">
                    <div className="input-group input-group-lg">
                    <button className="btn btn-block btn-lg btn-primary" onClick={e => {e.preventDefault(); handleSubmission()}}>Register</button>
                    </div>
                </div>
            </form>
        </div>    
    );
};