import * as React from 'react';
import '../../scss/style.scss';
import Navbar from 'react-bootstrap/Navbar';
import './style.scss';



export default function LoginNavigationBar(){    
  
  const logo = require('../../images/logo-full.png')
    return (
      <Navbar bg="light" expand="lg" fixed="top">
        <div className="container">
          <Navbar.Brand className="cb-brand">                
                <img src={logo} className="logo-img" alt="Central Bodyshop Paintgun Logo" />
          </Navbar.Brand>
        </div>
      </Navbar>     
    );
};
