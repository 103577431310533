import { FETCH_CONTENT, UPDATE_CONTENT, PREVIEW_CONTENT, FETCH_CONTENT_PENDING } from '../action-types';
import { IContent} from '../../components/PageContent';
import { IReducerAction } from '../IReducerAction';


export class ContentState{
    loading = false;
    content?: any;
    contents: IContent[];
    preview: IContent[];
    error?: any;

    constructor(){
        this.content= null;
        this.contents = [{id:"", pageId:"", content:""}];
        this.preview = [{id:"", pageId:"", content:""}]; 
        this.error = null;
    }
}

export default function contentReducer(state = new ContentState(), action: IReducerAction) {
    const {type, payload} = action || {} as IReducerAction;
    switch (type) {
        case FETCH_CONTENT: return {...state, contents: payload as IContent[] }
        case FETCH_CONTENT_PENDING: return {...state, loading: true}
        case UPDATE_CONTENT: return { ...state, error: payload }
        case PREVIEW_CONTENT: return {...state, preview: payload}
        default:
            return state;
    }
}

