import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IUpdateUser } from '../../interfaces';
import updateUser, { resetUser, resetUpdateUser } from '../../Api/UpdateUser';
import { IAppState } from '../../state';
import { IUser } from '../../interfaces';
import './style.scss'

export interface ILogin {
    username: string;
    password: string;
}

interface IProps {
    user: IUser| null;
}


export default function UpdateUserForm(props?: IProps) {

    const
        [data, setData] = React.useState<IUpdateUser>({ id: "", firstName: "", lastName: "", username: "", password: "", token: "" }),        
        [warning, setWarning] = React.useState(""),        
        dispatch = useDispatch(),
        // selector = useSelector((state: IUserFormState) => state.user),
        updateState: { currentUser?: IUser | null, error?: any, updateSuccess: boolean, updateUser: IUser | null } = useSelector(({ userReducer }: IAppState) => userReducer),
        [showPassword, setShowPassword] = React.useState(false);

    React.useEffect(() => {
        //setUser(getUser);
        setData(getUser)
        if (updateState.updateSuccess) {               
            if(updateState.currentUser?.id === updateState.updateUser?.id){
                setWarning(`User: ${updateState.currentUser?.username} - successfully updated`);        
                updateState.currentUser && dispatch(resetUser(updateState.currentUser));
            } else {
                setWarning(`User: ${updateState.updateUser?.username} - successfully updated`);                
                updateState.updateUser && dispatch(resetUpdateUser(updateState.updateUser))
            }
        }
        updateState.error && setWarning(updateState.error);
    }, [updateState.currentUser, updateState.updateSuccess])


    const getUser = () => {
        var tempUser = updateState.updateUser ? 
            props?.user && (props?.user.id === updateState.updateUser.id ) ? updateState.updateUser : props?.user :
            props?.user ? props?.user : updateState.currentUser;        

        var result = tempUser ?
            {
                id: tempUser.id,
                firstName: tempUser.firstName,
                lastName: tempUser.lastName,
                username: tempUser.username,
                password: "",
                token: updateState.currentUser?.token
            }:
            { id: "", firstName: "", lastName: "", username: "", password: "", token: "" };
        
            return result as IUpdateUser;
    }

    const toggleShow = (show: boolean) => {
        setShowPassword(show);
    };

    const handleSubmission = () => {
        dispatch(updateUser(data));
    }

    return (
        <div className="container">
            <div className={`${updateState.error ? "text-danger" : "text-success"} lead font-weight-bold`}>{warning}</div>
            <form id="contact-form">
                <div className="form-group">
                    <div className="input-group input-group-lg">
                        <div className="input-group-prepend"><span className="input-group-text" id="firstname-addon">First Name:</span></div>
                        <input type="text" className="form-control" value={data.firstName || ""} onChange={e => setData({ id: data.id, firstName: e.target.value, lastName: data.lastName, username: data.username, password: data.password, token: data.token })} required
                            id="firstNameInput" name="firstName" tabIndex={1} aria-describedby="firstname-addon" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="input-group input-group-lg">
                        <div className="input-group-prepend"><span className="input-group-text" id="lastname-addon">Last Name:</span></div>
                        <input type="text" className="form-control" value={data.lastName || ""} onChange={e => setData({ id: data.id, firstName: data.firstName, lastName: e.target.value, username: data.username, password: data.password, token: data.token })} required
                            id="lastNameInput" name="firstName" tabIndex={2} aria-describedby="lastname-addon" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="input-group input-group-lg">
                        <div className="input-group-prepend"><span className="input-group-text" id="username-addon">Username:</span></div>
                        <input type="text" className="form-control" value={data.username || ""} onChange={e => setData({ id: data.id, firstName: data.firstName, lastName: data.lastName, username: e.target.value, password: data.password, token: data.token })} required
                            id="nameInput" name="userName" tabIndex={3} aria-describedby="username-addon" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="input-group input-group-lg">
                        <div className="input-group-prepend"><span className="input-group-text" id="password-addon">Password:</span></div>
                        <input type={showPassword ? "text" : "password"} className="form-control password" onBlur={e => { e.preventDefault(); toggleShow(false) }} onChange={e => setData({ id: data.id, firstName: data.firstName, lastName: data.lastName, username: data.username, password: e.target.value, token: data.token })} required
                            id="password" tabIndex={4} aria-describedby="password-addon buttonShow" value={data.password || ""} />
                        <div className="input-group-append"><button type="button" id="buttonShow" name="buttonShow" onClick={e => { e.preventDefault(); toggleShow(!showPassword) }} className={showPassword ? "far fa-eye-slash fa-2x" : "far fa-eye fa-2x"} /></div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="input-group input-group-lg">
                        <button className="btn btn-block btn-lg btn-primary" onClick={e => { e.preventDefault(); handleSubmission() }}>Update</button>
                    </div>
                </div>
            </form>
        </div>
    );
};