export const FETCH_INTRO_DATA = 'FETCH_INTRO_DATA'; 
export const UPDATE_INTRO_DATA = 'UPDATE_INTRO_DATA'; 
export const UPDATE_INTRO_ERROR = 'UPDATE_INTRO_ERROR'; 

export const FETCH_CONTENT = 'FETCH_CONTENT'; 
export const FETCH_CONTENT_PENDING = 'FETCH_CONTENT_PENDING';
export const UPDATE_CONTENT = 'UPDATE_CONTENT'; 
export const UPDATE_CONTENT_ERROR = 'UPDATE_CONTENT_ERROR'; 
export const PREVIEW_CONTENT = "PREVIEW_CONTENT";

export const FETCH_CONTENT_ABOUT = 'FETCH_CONTENT_ABOUT'; 
export const UPDATE_CONTENT_ABOUT = 'UPDATE_CONTENT_ABOUT'; 
export const UPDATE_CONTENT_ABOUT_ERROR = 'UPDATE_CONTENT_ABOUT_ERROR'; 

export const FETCH_CONTENT_SERVICES = 'FETCH_CONTENT_SERVICES'; 
export const UPDATE_CONTENT_SERVICES = 'UPDATE_CONTENT_SERVICES'; 
export const UPDATE_CONTENT_SERVICES_ERROR = 'UPDATE_CONTENT_SERVICES_ERROR'; 

export const LOGIN_ERROR ="LOGIN_ERROR"; 
export const LOGIN_USER = "LOGIN_USER"; 
export const LOGIN_USER_PENDING = "LOGIN_USER_PENDING"; 

export const REGISTER_USER ="REGISTER_USER";
export const REGISTRATION_ERROR = "REGISTRATION_ERROR";

export const UPDATE_USER ="UPDATE_USER";
export const UPDATE_ERROR = "UPDATE_ERROR";
export const RESET_UPDATE_SUCCESS = "RESET_UPDATE_SUCCESS";
export const RESET_UPDATE_USER = "RESET_UPDATE_USER";
export const RESET_USERS = "RESET_USERS";

export const GET_USERS = "GET_USERS";