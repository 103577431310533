export interface IUser{
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    token: string;    
}

export interface IUpdateUser {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    password: string;
    token: string;      
}

export interface IRegistration {
    firstName: string;
    lastName: string;
    username: string;
    password: string;
}

export interface ITabContent {
    content: any,
    tabName: string    
}

export enum Pages{
    Home,
    About,
    Services,
    Contact,
    Landing,
    Login,
    Register,
    Admin,
    UpdateUser,
    Users,
    Intro,
    LandingEdit
}