import { UPDATE_USER, UPDATE_ERROR, RESET_UPDATE_SUCCESS, RESET_UPDATE_USER } from '../../state/action-types';
import { API_URL } from '../../common';
import axios from 'axios';
import {  IUpdateUser, IUser } from '../../interfaces';
import { IReducerAction } from '../../state/IReducerAction';



export default function updateUser(updateUser: IUpdateUser) {
    return (dispatch: (arg0: IReducerAction) => any) => {
        const
            token = localStorage.getItem("token"),
            requestData = { firstName: updateUser.firstName, lastName: updateUser.lastName, username: updateUser.username, password: updateUser.password },

            config = {
                headers: { Authorization: `Bearer ${token}` }
            },
            url = `${API_URL}/User/${updateUser.id}`,
            user = { id: updateUser.id, firstName: updateUser.firstName, lastName: updateUser.lastName, username: updateUser.username } as IUser;
            

        axios.put(url, requestData, config)
            .then(res => {
                dispatch({
                    type: UPDATE_USER,
                    payload: user as IUser
                });
            })
            .catch((error: any) => {
                dispatch({
                    type: UPDATE_ERROR,
                    payload: "There has been a problem updating your user. Please try again" 
                })
            })


    };
};

export function  resetUser(user: IUser){
    return(dispatch:(arg:IReducerAction) => any) =>{
        dispatch({type: RESET_UPDATE_SUCCESS, payload:user})
    }
}

export function  resetUpdateUser(user: IUser){
    return(dispatch:(arg:IReducerAction) => any) =>{
        dispatch({type: RESET_UPDATE_USER, payload:user})
    }
}